<template>
  <div>分拣前抽检</div>
</template>

<script>
export default {
  name: "CheckBeforeSort"
}
</script>

<style scoped>

</style>